* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: Figtree, sans-serif;
    font-feature-settings: normal
}

:root {
    --back-green: rgb(12, 158, 184);
    --back-hover: rgb(135, 194, 204);
    --back-darkgreen: rgb(25, 76, 85);
    --back-white: white;
    --back-light: #efefef;

}

ext-danger {
    color: red;
    font-weight: 400 !important;
}

#header {
    width: 100%;
    border-bottom: solid 1px #ddd;
    position: fixed;
    top: 0;
    z-index: 10;
    background: white;
}

.header {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.header .header-left img {
    width: 50px;
    height: 50px;
    image-resolution: var(--back-red);
    border-radius: 50%;
}

.header .header-left h4 {
    font-size: 1.3rem;
    font-family: sans-serif !important;
}


.header-mobile {
    display: flex;
    gap: 20px;
}

.header-right .header-links {
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: solid 1px #ccc;
}

.header-right .header-links ul {
    display: flex;
    gap: 24px;
}

.header-right .header-links ul li {
    list-style: none;
    /* margin: 0 15px; */
}

.header-right .header-links ul li a {
    text-decoration: none;
    color: #666;
    font-size: 15px;
}

.active-link-large {
    border-bottom: 2px solid black;
    padding-bottom: 20px;
}

.active-link-mobile {
    border-bottom: 2px solid black;
    padding-bottom: 15px;
}

.header-right .header-contact {
    display: flex;
    align-items: center;
}

.header-right .header-contact ul {
    display: flex;
    gap: 24px;
}

.header-right .header-contact ul li {
    list-style: none;
}

.header-right .header-contact ul li a {
    text-decoration: none;
    color: #666;
    font-size: 15px;
}

.menu-mobile {
    display: none;
}


@media screen and (max-width: 555px) {
    /* start of phone styles */

    /* header mobile view  */
    .header {
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-left {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .header .header-left img {
        width: 45px;
        height: 45px;
    }

    .header .header-left h4 {
        font-size: 20px;
        color: #333;
    }

    .header .header-right {
        display: block;
        gap: 20px;
        position: fixed;
        background: white;
        width: 100%;
        left: 0;
        right: 0;
        top: 55px;
        border-bottom: solid 1px #dddd;
    }

    .padding-nav {
        padding: 20px 0 !important;
        transition: 0.2s !important;
    }

    .header-mobile {
        width: 60%;
        display: block;
        margin: auto;
        animation: identifier 1s both;
        height: 0;
        overflow: hidden;
    }


    @keyframes identifier {
        from {
            opacity: 0.4;
            margin-top: -20px;
        }

        to {
            opacity: 1;
            margin-top: 0px;
        }
    }

    .header-right .header-links {
        display: flex;
        align-items: center;
        padding-right: 0px;
        border-right: none;
    }

    .header-right .header-links ul {
        display: block;
        gap: 24px;
        width: 100%;
    }

    .header-right .header-links ul li {
        list-style: none;
        padding: 12px 0;
        border-bottom: solid 1px #ddd;
        width: 100%;
    }


    .header-right .header-links ul li a {
        text-decoration: none;
        color: #666;
        font-size: 15px;
    }


    .header-right .header-contact {
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding: 10px 0;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 10px;
    }

    .header-right .header-contact ul {
        display: flex;
        gap: 24px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .header-right .header-contact ul li {
        list-style: none;
    }

    .header-right .header-contact ul li a {
        text-decoration: none;
        color: #666;
        font-weight: bold;
        font-size: 17px;
    }

    .menu-mobile {
        display: block;
        padding-right: 10px;
    }

    .menu-mobile #menus {
        font-size: 17px;
        font-weight: 600;
    }

    .menu-mobile #menus.afterMenu::before {
        content: "\f00d";
    }

    /* header mobile view  */

}


.home {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.75),
            rgba(0, 0, 0, 0.75)),
        url("./pages/assets/main2.png");
    background-size: cover;
    display: flex;

}

.home-content {
    width: 80%;
    text-align: center;
    margin: auto;
    color: white;
}

.home-content h4 {
    font-size: 2.3rem;
}

.home-content p {
    font-size: 20px;
    line-height: 40px;

}

.start {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 20px;
    margin-top: 30px;
}


.start .start-links a {
    text-decoration: none;
    color: white;
    padding: 13px 30px;
    font-size: 16px;
    position: relative;
    border: solid 1px var(--back-green);
    z-index: 1;
    border-radius: 5px;
    text-transform: uppercase;
}

.start .start-links a::before {
    content: "";
    position: absolute;
    background: var(--back-green);
    height: 100%;
    width: 0%;
    left: 0;
    top: 0;
    transition: 0.5s;
    z-index: -1;
    border-radius: 5px;
}


.start .start-links a:hover::before {
    width: 100%;
    color: black;
}


/* service */
.service {
    width: 85%;
    margin: auto;
}

.service-head {
    width: 100%;
    text-align: center;
    margin: 40px 0;
}

.service-head h4 {
    font-size: 2rem;
}

.service-head p {
    font-size: 17px;
}


.welcome-grid {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
}

.welcome-grid a {
    text-decoration: none;
}

.welcome-grid .col-6 {
    box-shadow: 0px 10px 2rem 0px #efefef;
    padding: 1.6rem;
    background: white;
    border-radius: 10px;
    min-height: 100%;
    transition: 0.5s;
}

.welcome-grid .col-6:hover {
    transform: scale(102%);
}



.card {
    width: 100%;
    margin: auto;
    margin-top: 10px;
}

.card .card-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(160, 238, 238, 0.4);
    display: flex;

}
.card img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: -20px;
}

.card .card-icon i {
    margin: auto;
    font-size: 15px;
    color: var(--back-green) !important;
}

.content i {
    color: var(--back-green) !important;
}

.card .card-text {
    margin-top: 20px;
}

.card .card-text h4 {
    font-size: 20px;
    color: #333;
    font-family: Figtree, sans-serif;
    font-weight: 600;
}

.card .card-text p {
    color: #555 !important;
    font-size: 14px;
    margin-top: 15px;
    font-family: Figtree, sans-serif;
    padding-bottom: 5px;
}

.photo {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 20px !important;

}

.content-img {
    width: 100% !important;
    height: 170px !important;
    object-fit: cover !important;
    object-fit: cover;
    border-radius: 0px !important;
}

.text-center {
    text-align: center;
}

.width-90 {
    width: 90%;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.noaphp-sponse a {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    color: #666;
}

.justify-space {
    display: flex;
    width: 96%;
    gap: 20px;
    justify-content: space-between;
}

.text-defult {
    color: var(--back-red);
    font-size: 20px;
}

.vertion p {
    color: #666;
    font-size: 14px;
}

/* service */


/* footer  */

.footer {
    width: 100%;
    margin-top: 80px;
    background: #eeeded;
    min-height: 200px;
    padding: 20px 0;
    position: relative;
}

.footer-header {
    width: 100%;
    text-align: center;
    padding-top: 20px 0;
    margin-top: 30px;
}

.footer img {
    max-width: 200px;
    max-height: 200px;
    left: 50%;
    position: absolute;
    content: "";
    transform: translate(-50%, -50%);
    opacity: 0.4;
    top: 40%;

}

.footer-header p {
    font-size: 20px;
}

.footer-header h4 {
    font-size: 1.7rem;
}

.footer-row {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.text-right {
    text-align: right;
}

.footer-clock {
    margin: 10px 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.justify-space-right {
    justify-content: right;
}

.footer-clock i {
    color: var(--back-green);
}

.footer-clock p {
    font-size: 15px;
}

.footer-icons {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
}

.footer-links {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}

.footer-links a {
    text-decoration: none;
    color: var(--back-green);
    width: 40px;
    height: 40px;
    display: flex;
    /* border: solid 1px var(--back-green); */
    transition: 0.4s;
    /* border-radius: 100%; */
}

.footer-links a i {
    margin: auto;
}

.footer-links a:hover {
    background: var(--back-green);
    color: white;
}

.footer-icons p {
    font-size: 15px;
    margin-top: 10px;
}
.welcome-grid-4{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 8px;
}
.gallery-col{
    width: 100%;
    margin-top: -8px;
    position: relative;
}
.gallery-col img{
    width: 100%;
    height: 260px;
    border-radius: 5px;
    transition: 0.4s ease;
    cursor: pointer;
    object-fit: cover;
}

.gal-content{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 60%);
    font-family: Figtree, sans-serif;
    padding: 10px;
    font-size: 14px;
    opacity: 0;
}
.gallery-col img:hover + .gal-content{
    /* box-shadow: 0 2px 2.3rem 0 #efefef; */
    transform: scale(101%);
    opacity: 1;
    transition: 0.6s ease;
}
.gal-content:hover{
    opacity: 1;
    transition: 0.6s ease;

}

.follow-admins{
    display: flex;
    gap: 10px;
    margin: 10px 0;
    width: 100%;
    justify-content: center;
}

.follow-admins a {
    text-decoration: none;
    color: var(--back-green);
    width: 30px;
    height: 30px;
    display: flex;
    border: solid 1px var(--back-green);
    transition: 0.4s;
    border-radius: 5px;
}

.follow-admins a i {
    margin: auto;
    font-size: 14px;
}

.follow-admins a:hover {
    background: var(--back-green);
    color: white;
}

@media screen and (max-width: 898px) {
    .footer-row {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .sm-mt-6 {
        margin-top: 2rem;
    }

    .welcome-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .welcome-grid-4{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .photo {
        grid-template-columns: 100%;
    
    }
    
    .content-img {
        height: 170px;
    }
}



/* footer  */